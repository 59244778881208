.sections {
  padding: 5px 5px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.section_item {
  padding: 3px 5px;
  margin: 3px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: '기본체' !important;
  font-size: smaller;
}

.section_item:hover {
  background-color: #363636;
  color: white;
}

.section_item_active {
  color: white;
  font-weight: bolder;
  background-color: #363636;
}

.section_item_inactive {
  background-color: white;
}

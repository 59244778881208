.container {
  margin: 15px 5px 0 5px;
  flex: 1;
}

.header {
  font-family: '기본체' !important;
  padding: 5px 5px;
  text-align: center;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
  border-bottom: 4px double #ddd;
}

.header_right0 {
  font-family: '기본체' !important;
  padding: 5px 5px;
  text-align: center;
  vertical-align: middle !important;
  border-bottom: 4px double #ddd;
}

.header_bottom0 {
  font-family: '기본체' !important;
  padding: 5px 5px;
  text-align: center;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
  border-bottom: 0;
}

.send_button {
  cursor: pointer;
  font-family: '기본체' !important;
  border-radius: 5px;
  padding: 0 3px;
  margin: 2px;
  font-size: smaller;
  width: 90%;
  border: 1px solid #ddd;
}

.readonly {
  color: #999;
}

.data {
  font-family: '기본체' !important;
  text-align: center;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.data_fixed {
  font-family: '기본체' !important;
  border-bottom: 4px double #ddd;
}

.data_fixed_text {
  font-family: '기본체' !important;
  text-align: center;
  padding-right: 10px;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
  border-bottom: 4px double #ddd;
}

.data_text {
  font-family: '기본체' !important;
  text-align: left;
  padding-left: 10px;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.data_text_small {
  font-family: '기본체' !important;
  font-size: x-small;
  color: #ccc;
  padding-left: 5px;
}

.data_right0 {
  font-family: '기본체' !important;
  text-align: center;
  vertical-align: middle !important;
  border-bottom: 1px solid #ddd;
}

.data_total {
  font-family: '기본체' !important;
  text-align: center;
  vertical-align: middle !important;
  border-right: 1px solid #ddd;
}

.data_total_filtered {
  border-top: 3px double #ddd;
  border-bottom: 3px double #ddd;
}

.data_total_filtered_right {
  border-right: 0;
  border-top: 3px double #ddd;
  border-bottom: 3px double #ddd;
}

.data_total_all {
  border-top: 4px double #ddd;
  border-bottom: 4px double #ddd;
}

.data_total_all_right {
  border-right: 0;
  border-top: 4px double #ddd;
  border-bottom: 4px double #ddd;
}

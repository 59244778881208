.container {
  display: flex;
  padding: 0.01rem;
  width: 100%;
}

.td_committee {
  padding: 0 !important;
}

.textInput {
  width: 90%;
  border: 0 !important;
}

.button {
  margin: 0.2rem;
  padding: 1rem;
}

.wideButton {
  margin: 0.2rem;
  padding: 1rem 3rem;
}

.disabled {
  background-color: #f0f0f0;
  color: #c0c0c0;
}

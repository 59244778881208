.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title h1 {
  font-size: 2.3em;
  font-weight: 600;
  margin-top: 10px;
  letter-spacing: 1px;
}
